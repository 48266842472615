<template>
  <div id="slider">
    <vue-slider
        v-model="value"
        :data="data"
        :tooltip="'none'"
        :tooltip-formatter="formatter"
        :marks="true"
        :max="3"
        :height="6"
        :dotSize="10"
        :contained="true"
    >
      <template v-slot:dot>
        <img :src="'/img/pin.svg'" height="30" class="custom-dot"/>
      </template>
    </vue-slider>

    <div v-if="withSlots" class="slot-container pt-60">
      <h5 class="color-primary slot-title">{{slots[value].title}}</h5>
      <p>{{slots[value].description}}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Slider',
    components: {},
    props: {
      theme: {
        type: String,
        default: ''
      },
      withSlots: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        value: 'scegli',
        formatter: () =>
            ''
        ,
        data: ['scegli', 'sfidaci', 'attiva', 'stupisci', 'controlla', 'modifica', 'resta'],
        slots: {
          'scegli': {
            title: 'Scegli',
            description: 'Preparati a partire scegliendo l’equipaggiamento che fa per te. Seleziona i device più adatti alle necessità dei tuoi team, le caratteristiche che non possono mancare, i servizi di supporto durante il viaggio della tua azienda e un canone che ti permetterà di massimizzare l’investimento IT.'
          },
          'sfidaci': {
            title: 'Sfidaci',
            description: 'Saremo il tuo unico interlocutore per tutta la durata del percorso. Noltech mette a tua disposizione non solo un team di tecnici in grado di rispondere alle esigenze relative alla gestione dei dispositivi, ma anche consulenti esperti che si occupano delle pratiche amministrativa per offrirti un contratto davvero trasparente e flessibile.'
          },
          'attiva': {
            title: 'Attiva',
            description: 'Una volta preparato l’equipaggiamento, i tuoi collaboratori saranno pronti per partire. Infatti, in Noltech ci occupiamo di tutte le fasi di preparazione, di configurazione e di consegna dei dispositivi in tempi record, dove vuoi, in Italia e in Europa.'
          },
          'stupisci': {
            title: 'Stupisci',
            description: 'Rompere le scatole non è mai stato così divertente! Sappiamo quanto ricevere un servizio personalizzato sia gratificante, per questo insieme ai device i tuoi collaboratori troveranno un communication kit brandizzato con un messaggio di benvenuto e le informazioni necessarie per utilizzare al meglio il loro nuovo dispositivo.'
          },
          'controlla': {
            title: 'Controlla',
            description: 'La governance degli asset IT non è mai stata così intuitiva. Tra i servizi inclusi nel DaaS di Noltech, avrai accesso a MyElmec, una piattaforma unica dalla quale puoi monitorare lo status dei singoli dispositivi, i ticket e anche i dettagli amministrativi legati al tuo canone.'
          },
          'modifica': {
            title: 'Modifica',
            description: 'Tra i nostri pilastri ci sono flessibilità, trasparenza e semplicità. Puoi infatti decidere di terminare il contratto in qualsiasi momento senza costi aggiuntivi. Ritireremo noi i dispositivi e ci occuperemo dello smaltimento e delle procedure certificate di wiping.'
          },
          'resta': {
            title: 'Resta con noi',
            description: 'Nuovi viaggiatori sono pronti per partire? Vorresti aggiornare l’equipaggiamento a vostra disposizione? Nessun problema! Puoi aggiungere o modificare gli asset IT tutte le volte che vuoi.'
          }
        }
      }
    },
  }
</script>

<style lang="less">
  #slider {
    padding: 0 10px;
    display: flex;
    flex-direction: column;

    .vue-slider {
      .slick-dots {
        background-color: transparent; /* bg color of container */
      }
      .slick-dots button:before {
        color: #000; /* color of dots */
        opacity: .25; /* opacity of dots */
        background-color: transparent; /* bg color of each "button"
                                  * (blends with the one set in .slick-dots
                                  * if opacity is not 1) */
        content: "•"; /* this is the actual dot (yep, it's text)
                 * set it to ❥, 🤡 or whatever other string you want);
             NOTE: don't set it to `none`: the dots won't display! */
        font-size: 10px; /* font-size of the dot */
      }
      .slick-dots .slick-active button:before {
        /* everything already listed under `.slick-dots button:before`
           except `opacity` is set to 1 by default */
      }
      .vue-slider-rail {
        cursor: pointer;
      }

      .vue-slider-mark {
        @media (max-width: 576px)  {
          &:nth-child(even) {
            .vue-slider-mark-label {
              margin-top: -50px;
            }
          }
          &:nth-child(odd) {
            .vue-slider-mark-label {
              margin-top: 20px;
            }
          }
        }

        .vue-slider-mark-label {
          text-transform: capitalize;
        }
      }


      .custom-dot {
        position: absolute;
        margin-top: -13px;
        margin-left: -4px;
      }
    }

    .slot-container {
      min-height: 200px;
    }
  }
</style>
